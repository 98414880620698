<template>
  <div  class="lp-every-shade">
    <div class="container mx-auto">
      <div class="heroBannerWrap">
        <h1 class="heroBannerWrap_text_title displayDesktop md:block sm:hidden">Find every shade of you</h1>
        <h1 class="heroBannerWrap_text_title displayMobile">Find every <br> shade of you</h1>
        <p class="heroBannerWrap_text_hastag text-base">There’s a style for every mood and every moment.</p>
      </div>
    </div>
    <div class="videoFotoContainer_center">
      <div class="grid gap-0 md:grid-cols-4 sm:grid-cols-2">
        <div class="video-item" v-for="(videoitem, i) in videoitems" :key="i" @mouseover="showByIndex = i" @mouseout="showByIndex = null">
          <a :href="'/products/'+videoitem.url" class="card">
          <div>
          <img v-if="showByIndex === i" v-show="showByIndex === i" :src="`${imageBaseCms}${videoitem.imageTwo}`" :alt="`${videoitem.name}`" />
          <img  v-else :src="`${imageBaseCms}${videoitem.imageOne}`" :alt="`${videoitem.name}`" />
            </div>
            <div class="video-info text-center md:py-4 sm:py-3 text-base underline">
              SHOP {{ videoitem.name }}  STYLES
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="product-block-section md:py-8 sm:py-4">
      <template v-for="(productblock, index) in productblocks" :key="index">
        <template v-if="index == current">
          <div class="grid md:gap-8 sm:gap-4 md:grid-cols-2 sm:grid-cols-2">
            <div class="product-block md:row-span-2">
              <div class="inner">
                <a :href="productblock.productUrl1">
                <img :src="`${imageBaseCms+'On-Model/'+productblock.imageOne}`" :alt="`${productblock.name}`" class="fes-img1"/>
                <div class="wrapBagIcon">

                  <div class="brandPrice_tile text-base sm:text-xs">{{ productblock.brandNameOne }}</div>
                </div>
                </a>
              </div>
            </div>
            <div class="product-block md:max-w-3xl ">
              <div class="inner">
                <a :href="productblock.productUrl2">
                <img :src="`${imageBaseCms+'On-Model/'+productblock.imageTwo}`" :alt="`${productblock.name}`" class="fes-img2"/>
                <div class="wrapBagIcon">
                  <div class="brandPrice_tile text-base sm:text-xs">{{ productblock.brandNameTwo }}</div>
                </div>
                </a>
              </div>
            </div>
            <div class="product-block lg:col-span-1 md:col-span-2 sm:col-span-2">
              <div class="product-block-details md:max-w-xl sm:max-w-md mx-auto text-left sm:px-6">
                <h3 class="sliderNumber text-lg"> {{++index }}/{{ productblocks.length }}</h3>
                <h2 class="textThreeItems_title text-left">{{ productblock.name }}</h2>
                <p class="textSliderTop text-left my-4 text-base">{{ productblock.description }}</p>
                <a class="button bg-black text-white btn px-6 py-3" :href="'/products/' + productblock.url">Shop {{ productblock.name }}</a>
                <div class="flex gap-6 pt-6">
                  <button @click="previous()" class="flex-none md:block btn-previous">
                    <img :src="`${imageBaseCms}/Arrow_left.svg`" width="60"
                      height="40" />
                  </button>
                  <button @click="next()" class="flex-none md:block btn-next">
                    <img :src="`${imageBaseCms}/Arrow_right.svg`" width="60"
                      height="40" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>

    <div class="container mx-auto">
      <CentermodeSlider v-bind:exclusiveitems="exclusiveitems" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/runtime-core"
import "./every-shade.scss";
import CentermodeSlider from "../../components/molecules/CentermodeSlider";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";
export default defineComponent({
  name: "everyshade",
  components: {
    CentermodeSlider,

  },
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Find Every Shade",
      description:
        "Sunglass Hut® South Africa Online Store | Find Every Shade",
    });

    const blockSlider = ref()
    function showPrev() {
      blockSlider.value?.prev();
    }
    function showNext() {
      blockSlider.value?.next();
    }
    return { blockSlider, showPrev, showNext }
  },
  data() {

    return {
      showByIndex: null,
       hover: false,
      active: true,
       flag: true,
      timer: null,
      current: 0,
      productblocks: [
         {
          brandNameOne: 'MICHAEL KORS / EXCLUSIVE',
          imageOne: "Urban_Ari_A_150_D.jpg",
          brandNameTwo: 'Ray-Ban',
          imageTwo: "Urban_Cesar_A_011_C.jpg",
          name: "Iconic styles",
          description: "In the mood for the classics? Explore timeless styles to go with every season and any occasion.",
          collections:"Iconic",
          url: "?collections=Iconic%2520Sunglasses",
          productUrl1: "/sunglasshut-sa/michael-kors/mk1045-san-diego--725125382830",
          productUrl2: "/sunglasshut-sa/Ray-Ban/RB3625-NEW-AVIATOR-8056597665070",
        },
        {
          brandNameOne: 'BURBERRY / EXCLUSIVE',
          imageOne: "Hotel+Auto_Essence_A_290_B.jpg",
          brandNameTwo: 'DOLCE&GABBANA',
          imageTwo: "Beach_Babs_A_036_B.jpg",
          name: "Luxury styles",
          description: "Feeling high fashion and fabulous? Shop styles designed to make an impression.",
          collections:"Luxury",
          url: "?collections=Luxury%2520Sunglasses",
          productUrl1: "/sunglasshut-sa/burberry/be4344-daisy-8056597696890",
          productUrl2: "/sunglasshut-sa/Dolce&Gabbana/DG4354--8056597019194",
        },
        {
          brandNameOne: 'OAKLEY / EXCLUSIVE',
          imageOne: "Beach_Cesar_A_003_C_2.jpg",
          brandNameTwo: 'OAKLEY',
          imageTwo: "Diner_Essence_A_065_C.jpg",
          name: "Active styles ",
          description: "Want to up your game? Check out the latest styles and find a pair to bring on all your adventures.",
          collections:"Active",
          url: "?collections=Active%2520Sunglasses",
          productUrl1: "/sunglasshut-sa/oakley/oo9463-sutro-lite-888392588746",
          productUrl2: "/sunglasshut-sa/Oakley/OO9013-FROGSKINS-888392327024",
        },
        {
          brandNameOne: 'VERSACE / EXCLUSIVE',
          imageOne: "Diner+Truck_Babs_A_051_C.jpg",
          brandNameTwo: 'VERSACE',
          imageTwo: "Diner+Truck_VIda_A_090_C.jpg",
          name: "On-trend styles",
          description: "Looking for the latest shades of the season? Add one of this year’s must-have styles to your wardrobe.",
          collections:"On-trend",
          url: "?collections=On-trend%2520Sunglasses",
          productUrl1: "/sunglasshut-sa/versace/ve4406-8056597699075",
          productUrl2: "/sunglasshut-sa/Versace/VE4361--8053672947397",
        }
      ],
      videoitems: [
        {
          imageOne:"Ray-Ban-Iconic-styles.png",
          imageTwo:"Ray-ban-Iconic-styles-h.png",
          name: "ICONIC",
          url: "?collections=Iconic%2520Sunglasses",
        },
        {
          imageOne:"Versace-Luxury-styles.png",
          imageTwo:"Versace-luxury-styles-h.png",
          name: "LUXURY",
          url: "?collections=Luxury%2520Sunglasses",
        },
        {
          imageOne:"Oakley-active-styles.png",
          imageTwo:"Oakley-active-styles-h.png",
          name: "ACTIVE",
          url: "?collections=Active%2520Sunglasses",
        },
        {
          imageOne:"Burberry-on-trend-styles.png",
          imageTwo:"Burberry-on-trend-styles-h.png",
          name: "ON-TREND",
          url: "?collections=On-trend%2520Sunglasses",
        },

      ],
      exclusiveitems: [
        {
          imageOne: "0PR_23YS__2AU06B__STD__shad__fr.png",
          name: "Prada",
          description: "Prada Symbole merges iconic details with contemporary design. This oversized shape has a reinterpreted Prada triangle logo on the temples and comes in an exclusive green tortoise color.",
          url: "/sunglasshut-sa/prada/pr-23ys-8056597627498",
        },
        {
          imageOne: "0BE4344__387413__STD__shad__fr.png",
          name: "Burberry",
          description: "A modern spin on a ‘60s retro shape with an acetate front, metal décor and the Thomas Burberry initials on the temples. The pink frame is complemented by brown gradient lenses.",
          url: "/sunglasshut-sa/burberry/be4344-daisy-8056597696890",
        },
        {
          imageOne: "0RB2298__1295M3_000A.png",
          name: "Ray-Ban",
          description: "Where comfort and character meet style. This bold acetate boasts the original keyhole bridge and relaxed lines for a ‘60s counterculture style in a Sunglass Hut exclusive color.",
          url: "/sunglasshut-sa/ray-ban/rb2298-hawkeye-8056597697255",
        },
        {
          imageOne: "0OO9463__946342__P21__shad__fr.png",
          name: "Oakley",
          description: "A hat-compatible style with Prizm™ lenses and an exclusive gold temple logo. The semi-rimless frame gives greater field of view and Unobtainium™ nose pads and earsocks give a no-slip grip.",
          url: "/sunglasshut-sa/oakley/oo9463-sutro-lite-888392588746",
        },
        {
          imageOne: "0MK1045__1014I8__P21__shad__fr.png",
          name: "Michael Kors",
          description: "Where comfort and character meet style. This bold acetate boasts the original keyhole bridge and relaxed lines for a ‘60s counterculture style in a Sunglass Hut exclusive color.",
          url: "/sunglasshut-sa/michael-kors/mk1045-san-diego--725125382830",
        },
        {
          imageOne: "0VE4406__GB1_7__STD__shad__fr.png",
          name: "Versace",
          description: "A head-turning black and gold Versace with a cut-out metal plaque and wide temples. Complete with the signature medallion & exclusive orange lenses in Sunglass Hut style.",
          url: "/sunglasshut-sa/versace/ve4406-8056597699075",
        },
      ],
    };
  },
  created() {
    
  },
  methods: {
      imageChange() {
        this.hover = true;
        this.active = false;
          this.flag1 = !this.flag1;
        },

    previous() {
      this.current = Math.abs(this.productblocks.length + (this.current - 1)) % this.productblocks.length;
    },
    next() {
      this.current++;
      if (this.current >= this.productblocks.length) {
        this.current = 0;
      }
    },

    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ],
  }
});

</script>
