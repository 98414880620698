<template>
  <div class="text-component mb-0">
    <div class="wrapIntroExclusive md:max-w-xl sm:max-w-xs sm:mx-10">
      <img class="imgLogo displayDesktop"
        :src="`${imageBaseCms}SGH_exclusive_D.webp`" alt="img exclusive">
      <img class="imgLogo displayMobile"
        :src="`${imageBaseCms}SGH_exclusive_M.webp`" alt="img exclusive">
    </div>
    <p class="mt-4 text-base font-light">
      Express Your unique style with our selection of styles available only at
      Sunglass Hut.
    </p>
  </div>
  <div class="CentermodeSlider">
    <div class="md:flex sm:block  gap-3">
      <button @click="showPrev" class="flex-none md:block sm:hidden">
        <img :src="`${imageBaseCms}Arrow_left.svg`" width="60" height="40" />
      </button>
      <div class="grow">
        <Carousel :wrapAround="true" :snapAlign="center" :mouseDrag="false" :touchDrag="false"
          :breakpoints="breakpoints" ref="sliderCentermodeSlider" v-on:pageChange="pageChange">
          <Slide v-for="(exclusiveitem , index) in exclusiveitems" :key="index">
            <div class="carousel__item">
              <div>
                <img :src="`${imageBaseCms}${exclusiveitem.imageOne}`" :alt="`${exclusiveitem.name}`" class="imgPage " />
              </div>
              <div class="item-details">
                <h3> {{ exclusiveitem.name.toUpperCase() }}</h3>
                <p class="text-description text-base">
                  {{ exclusiveitem.description }}
                </p><a :href="exclusiveitem.url"
                  class="button">Shop Now</a>
              </div>
            </div>
          </Slide>
        </Carousel>

      </div>
      <button @click="showNext" class="flex-none md:block sm:hidden">
        <img :src="`${imageBaseCms}/Arrow_right.svg`" width="60" height="40" />
      </button>
    </div>

    <div class="flex gap-6 pn-6 justify-center  md:hidden sm:flex">
       <button @click="showPrev" class="flex-none">
        <img :src="`${imageBaseCms}Arrow_left.svg`" width="60" height="40" />
      </button>
       <button @click="showNext" class="flex-none">
        <img :src="`${imageBaseCms}/Arrow_right.svg`" width="60" height="40" />
      </button>
    </div>
  </div>

</template>
<script>
import { defineComponent, ref } from "@vue/runtime-core"
import "vue3-carousel/dist/carousel.css";
import "./CentermodeSlider.scss";
import { Carousel, Slide} from "vue3-carousel";
export default defineComponent({
  name: "CentermodeSlider",
  components: {
    Carousel,
    Slide
  },
  props: ['exclusiveitems'],
  setup() {
    const sliderCentermodeSlider = ref()
    function showPrev() {
      sliderCentermodeSlider.value?.prev();
    }
    function showNext() {
      sliderCentermodeSlider.value?.next();
    }
    return { sliderCentermodeSlider, showPrev, showNext }
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 1,
        },
        1024: {
          itemsToShow: 2,
        }
      }
    };

  },
});
</script>
